import React, {useContext} from 'react';

export type Language = "EN_US" | "DA_DK" | "SV_SE" | "NB_NO"
export const LanguageContext = React.createContext<Language>("EN_US");

export function assertUnreachable(x: never): never {
  throw new Error("Didn't expect to get here");
}

export function useLanguage() {
  return useContext(LanguageContext);
}

function applyReplacements(input: string, replacements?: {[key: string]: any}) {
  if (!replacements) return input;
  return Object.keys(replacements).reduce((memo, key) => {
    return input.replace(`{${key}}`, replacements[key]);
  }, input);
}

export default function useTranslate() {
  const language = useLanguage();

  return (input: TranslationKey, replacements?: {[key: string]: any}) => {
    switch (language) {
      case "EN_US": return applyReplacements(input, replacements);
      case "DA_DK": return applyReplacements(DA_DK_TRANSLATIONS[input], replacements);
      case "SV_SE": return applyReplacements(SV_SE_TRANSLATIONS[input], replacements);
      case "NB_NO": return applyReplacements(NB_NO_TRANSLATIONS[input], replacements);
    }

    assertUnreachable(language);
  }
}

type Translation = {
  [key in TranslationKey]: string
}

type TranslationKey =
  /* ConfirmModal */
  'Cancel' |
  'Confirm & continue' |
  'Continue' |
  /* AnonymousViewerScreen */
  'An error occurred' |
  'You have ended up here by mistake, please go back to the person that invited you to sign and get a proper signature link.' |
  /* EvidenceValidationErrorScreen */
  'The used e-ID does not match the person expected to sign these documents.' |
  'The used e-ID does not match any signed documents.' |
  'If you are signing on behalf or in company of another person, like your spouse, please ensure you are using the intended e-ID.' |
  'Sign with new e-ID' |
  'Retry' |
  /* DuplicateEvidenceErrorSCreen */
  'The e-ID you used has already previously been used to sign these documents.' |
  'Please make sure you are using your personal e-ID when signing.' |
  /* ErrorScreen */
  'An error occurred. Please try again in 5 minutes' |
  'Our system is temporarily down or your network connection may be unstable.' |
  /* SignatoryViewerScreen */
  'Approve and sign' |
  'Approve & sign' |
  'Open and approve the document below' |
  'Open and approve the {documents} documents below' |
  'You must approve all documents before you can sign' |
  'An error has occurred with the signature process.' |
  'Sign' |
  'I do not want to sign' |
  'documents' |
  'document' |
  'Signed' |
  'You have not signed' |
  'You will be redirected...' | 
  'Reject' |
  'Approve' |
  'We are processing your data...' |
  'Hold on a second...' |
  'Let us know why you do not want to sign.' |
  'Don\'t see the popup?' |
  'Try again' |
  'An error occurred while authenticating your e-ID to sign these documents.' |
  'An error occurred while authenticating your e-ID.' |
  'Loading PDF' |
  'Open' |
  /* DrawableEvidenceProviderScreen */
  'Back' |
  'Next' |
  'Erase' | 
  'Draw your signature here' | 
  'Date' | 
  'Full name' |
  'Turn your phone horizontal' |
  '(Having issues? Check if your phone has auto-rotation enabled...)' |
  'Type in your full name' | 
  'Type in your full name in the field below' |
  'You must enter your full name before submitting' |
  'You must draw your signature before submitting' |
  'You must draw your signature and enter your full name before submitting' |
  'Approve your signature' |
  'You must draw a larger signature before submitting' |
  /* SignScreen */
  'Choose how you want to sign' | 
  'With your e-ID' |
  'Draw your signature' |
  'Sign flow on mobile device cancelled' |
  'Complete the signing flow on your mobile device' |
  'Or sign with your mobile device by scanning the QR code' |
  /* SignatoryDeletedScreen */
  'Invalid signature link' |
  'The signature link you followed is no longer valid, please request a new one from the person or organization that invited you to sign' |
  /* SignatureOrderNotOpenScreen */
  'You are currently not able to sign any documents. Please contact the person or organization that invited you to sign.' |
  'Signature order has been cancelled' |
  'Signature order has expired' |
  'Signature order is unavailable' |
  /* DownloadScreen */
  'Download unavailable' | 
  'Documents are not available yet, or you are not allowed to download.' |
  'Verify your identity to download documents' | 
  'Download documents' |
  'Download expired' |
  'Documents are no longer available.' | 
  /* UnvalidatedSignatoryViewerScreen */
  'You must identify yourself before you can view and sign the documents.';


const DA_DK_TRANSLATIONS : Translation = {
  /* ConfirmModal */
  'Cancel': 'Fortryd',
  'Confirm & continue': 'Bekræft & fortsæt',
  'Continue': 'Fortsæt',
  /* AnonymousViewerScreen */
  'An error occurred':
    'Der skete en fejl',
  'You have ended up here by mistake, please go back to the person that invited you to sign and get a proper signature link.':
    'Du er endt her ved en fejl, vær venlig at tag kontakt til personen der inviterede dig til at underskrive.',
  /* EvidenceValidationErrorScreen */
  'The used e-ID does not match the person expected to sign these documents.':
    'Det valgte e-ID matcher ikke den person vi forventer skal underskrive dokumenterne.',
  'The used e-ID does not match any signed documents.': 
    'Det valgte e-ID matcher ikke nogle af de underskrevne dokumenter',
  'If you are signing on behalf or in company of another person, like your spouse, please ensure you are using the intended e-ID.':
    'Hvis du underskriver på vegne eller i selskab med en anden person, som din partner, skal du sikre dig at du bruger den rigtige persons e-ID.',
  'Sign with new e-ID':
    'Underskriv igen',
  'Retry':
    'Prøv igen',
  /* DuplicateEvidenceErrorSCreen */
  'The e-ID you used has already previously been used to sign these documents.':
    'Det e-ID du har brugt er allerede blevet brugt til at underskrive tidligere.',
  'Please make sure you are using your personal e-ID when signing.':
    'Vær venlig at sikre dig at du bruger dit personlige e-ID.',
  /* ErrorScreen */
  'An error occurred. Please try again in 5 minutes':
    'Der skete en fejl. Vær venlig at prøve igen om 5 minutter.',
  'Our system is temporarily down or your network connection may be unstable.':
    'Vores system er midlertidigt nede eller din internet forbindelse er ustabil.',
  /* SignatoryViewerScreen */
  'Approve and sign':
    'Godkend og underskriv',
  'Approve & sign':
    'Godkend & underskriv',
  'Open and approve the document below':
    'Åbn og godkend dokumentet nedenunder',
  'Open and approve the {documents} documents below':
    'Åbn og godkend de {documents} dokumenter nedenunder',
  'You must approve all documents before you can sign':
    'Du skal godkende alle dokumenter før du kan underskrive',
  'An error has occurred with the signature process.':
    'Der skete en fejl i underskrivnings processen.',
  'Sign':
    'Underskriv',
  'I do not want to sign':
    'Jeg vil ikke underskrive',
  'documents':
    'dokumenter',
  'document':
    'dokument',
  'Signed':
    'Underskrevet',
  'You have not signed':
    'Du har ikke underskrevet',
  'You will be redirected...':
    'Du bliver videresendt...',
  'Reject':
    'Afvis',
  'Approve':
    'Godkend',
  'We are processing your data...':
    'Vi behandler dine data...',
  'Hold on a second...':
    'Vent et øjeblik...',
  'Let us know why you do not want to sign.':
    'Fortæl os hvorfor du ikke ønsker at underskrive',
  'Don\'t see the popup?': 
    'Kan du ikke se popup-vinduet?',
  'Try again': 
    'Prøv igen',
  'An error occurred while authenticating your e-ID to sign these documents.':
    'Der skete en fejl med dit e-ID og vi kan ikke underskrive dokumenterne.',
  'An error occurred while authenticating your e-ID.':
    'Der skete en fejl med dit e-ID',
  'Loading PDF':
    'Henter PDF',
  'Open':
    'Åbn',
  /* DrawableEvidenceProviderScreen */
  'Back':
    'Tilbage',
  'Next':
    'Videre',
  'Erase':
    'Visk ud',
  'Draw your signature here':
    'Tegn din underskrift her',
  'Date':
    'Dato',
  'Full name':
    'Fulde navn',
  'Type in your full name':
    'Indtast dit fulde navn',
  'Type in your full name in the field below':
    'Indtast dit fuldenavn i feltet herunder',
  'You must enter your full name before submitting':
    'Du skal indtaste dit fulde navn inden du kan godkende',
  'You must draw your signature before submitting':
    'Du skal tegne din underskrift inden du kan godkende',
  'You must draw your signature and enter your full name before submitting':
    'Du skal tegne din underskrift og indtaste dit fulde navn inden du kan godkende',
  'Turn your phone horizontal':
    'Drej telefonen vandret',
  '(Having issues? Check if your phone has auto-rotation enabled...)':
    '(Driller det? Tjek om du har låst din skærm til lodret visning...)',
  'Approve your signature':
    'Godkend din underskrift',
  'You must draw a larger signature before submitting':
    'Du skal tegne en større underskrift inden du kan godkende',
  /* SignScreen */
  'Choose how you want to sign':
    'Vælg hvordan du vil underskrive',
  'With your e-ID':
    'MitID, NemID eller andet e-ID',
  'Draw your signature':
    'Tegn din underskrift',
  'Sign flow on mobile device cancelled':
    'Underskrift via mobil annulleret',
  'Complete the signing flow on your mobile device':
    'Gennemfør login på din mobiltelefon for at underskrive.',
  'Or sign with your mobile device by scanning the QR code':
    'Eller underskriv med din mobiltelefon ved at scanne QR koden',
  /* SignatoryDeletedScreen */
  'Invalid signature link':
    'Ugyldigt signatur link',
  'The signature link you followed is no longer valid, please request a new one from the person or organization that invited you to sign':
    'Signatur linket du har fulgt er ikke længere gyldigt, vær venlig at bede om et nyt link fra personen eller organisationen der inviterede dig til at underskrive',
  /* SignatureOrderNotOpenScreen */
  'You are currently not able to sign any documents. Please contact the person or organization that invited you to sign.':
    'Du kan på nuværende ikke underskrive nogle dokumenter. Vær venlig at kontakt personen eller organisationen der inviterede dig til at underskrive',
  'Signature order has been cancelled':
    'Signaturordren er blevet annulleret',
  'Signature order has expired':
    'Signaturordren er udløbet',
  'Signature order is unavailable':
    'Signaturordren er ikke tilgængelig',
  /* DownloadScreen */
  'Download unavailable': 'Download utilgængelig',
  'Documents are not available yet, or you are not allowed to download.': 'Dokumenter er ikke tilgængelige, eller du har ikke ret til at downloade',
  'Verify your identity to download documents': 'Bekræft din identitet for at hente dokumenter',
  'Download documents': 'Hent dokumenter',
  'Download expired': 'Download udløbet',
  'Documents are no longer available.': 'Dokumenterne er ikke længere tilgængelige.',
  /* UnvalidatedSignatoryViewerScreen */
  'You must identify yourself before you can view and sign the documents.': 
    'Du skal identificere dig før du kan se og underskrive dokumenter.'
};

const SV_SE_TRANSLATIONS : Translation = {
  /* ConfirmModal */
  'Cancel': 'Avbryt',
  'Confirm & continue': 'Bekräft & fortsätt',
  'Continue': 'Fortsätt',
  /* AnonymousViewerScreen */
  'An error occurred':
    'Ett fel uppstod',
  'You have ended up here by mistake, please go back to the person that invited you to sign and get a proper signature link.':
    'Du har hamnat här av misstag, vänligen gå tillbaka till personen som bjöd in dig att skriva under och få en korrekt signaturlänk.',
  /* EvidenceValidationErrorScreen */
  'The used e-ID does not match the person expected to sign these documents.':
    'Den använda e-ID matchar inte den person som förväntas underteckna dessa dokument.',
  'The used e-ID does not match any signed documents.': 
    'Den använda e-ID matchar inte några signerade dokument',
  'If you are signing on behalf or in company of another person, like your spouse, please ensure you are using the intended e-ID.':
    'Om du skriver under på uppdrag av eller i sällskap med en annan person, som din make, vänligen se till att du använder den avsedda e-ID.',
  'Sign with new e-ID':
    'Signera igen',
  'Retry':
    'Försök igen',
  /* DuplicateEvidenceErrorSCreen */
  'The e-ID you used has already previously been used to sign these documents.':
    'Den e-legitimation du använde har redan tidigare använts för att signera dessa dokument.',
  'Please make sure you are using your personal e-ID when signing.':
    'Se till att du använder din personliga e-legitimation när du signerar.',
  /* ErrorScreen */
  'An error occurred. Please try again in 5 minutes':
    'Ett fel uppstod. Försök igen om 5 minuter',
  'Our system is temporarily down or your network connection may be unstable.':
    'Vårt system är tillfälligt nere eller så kan din nätverksanslutning vara instabil.',
  /* SignatoryViewerScreen */
  'Approve and sign':
    'Godkänn och signera',
  'Approve & sign':
    'Godkänn & signera',
  'Open and approve the document below':
    'Öppna och godkänn dokumentet nedan',
  'Open and approve the {documents} documents below':
    'Öppna och godkänn de {documents} dokumenten nedan',
  'You must approve all documents before you can sign':
    'Du måste godkänna alla dokument innan du kan skriva under',
  'An error has occurred with the signature process.':
    'Ett fel har uppstått med signaturprocessen.',
  'Sign':
    'Signera',
  'I do not want to sign':
    'Jag vill inte skriva under',
  'documents':
    'dokumenter',
  'document':
    'dokument',
  'Signed':
    'Signerat',
  'You have not signed':
    'Du har inte skrivit på',
  'You will be redirected...':
    'Du kommer att omdirigeras...',
  'Reject':
    'Avvisa',
  'Approve':
    'Godkänn',
  'We are processing your data...':
    'Vi behandlar dina uppgifter...',
  'Hold on a second...':
    'Vänta en sekund...',
  'Let us know why you do not want to sign.':
    'Berätta varför du inte vill skriva under.',
  'Don\'t see the popup?': 
    'Ser du inte popupen?',
  'Try again':
    'Försök igen',
  'An error occurred while authenticating your e-ID to sign these documents.':
    'Ett fel uppstod vid autentisering av ditt e-ID för att signera dessa dokument.',
  'An error occurred while authenticating your e-ID.':
    'Ett fel uppstod vid autentisering av ditt e-ID',
  'Loading PDF':
    'Läser in PDF',
  'Open':
    'Öppna',
  /* DrawableEvidenceProviderScreen */
  'Back':
    'Tilbaka',
  'Next':
    'Fortsätta',
  'Erase':
    'Radera',
  'Draw your signature here':
    'Rita din signatur här',
  'Date':
    'Date',
  'Full name':
    'Fullständiga namn',
  'Type in your full name':
    'Ange ditt fullständiga namn',
  'Type in your full name in the field below':
    'Ange ditt fullständiga namn i fältet nedan',
  'You must enter your full name before submitting':
    'Du måste ange ditt fullständiga namn innan du kan godkänna',
  'You must draw your signature before submitting':
    'Du måste rita din signatur inden innan du kan godkänna',
  'You must draw your signature and enter your full name before submitting':
    'Du måste rita din signatur och ange ditt fullständiga namn innan du kan godkänna',
  'Turn your phone horizontal':
    'Vrid telefonen horisontellt',
  '(Having issues? Check if your phone has auto-rotation enabled...)':
    'Har du problem? Kontrollera om din telefon har aktiverat automatisk rotation',
  'Approve your signature':
    'Godkänn din signatur',
  'You must draw a larger signature before submitting': 
    'Du måste skriva under med en större signatur innan du kan godkänna',
  /* SignScreen */
  'Choose how you want to sign':
    'Välj hur du vill signera',
  'With your e-ID':
    'Med BankID eller annan e-id',
  'Draw your signature':
    'Rita din signatur',
  'Sign flow on mobile device cancelled':
    'Signatur via mobil annullerad',
  'Complete the signing flow on your mobile device':
    'Fyll i inloggningen på din mobiltelefon för att signera',
  'Or sign with your mobile device by scanning the QR code':
    'Eller signera med din mobila enhet genom att skanna QR-koden',
  /* SignatoryDeletedScreen */
  'Invalid signature link':
    'Ogiltig signaturlänk',
  'The signature link you followed is no longer valid, please request a new one from the person or organization that invited you to sign':
    'Signaturlänken du har följt är inte längre giltig, begär en ny länk från den person eller organisation som bjöd in dig att skriva under',
  /* SignatureOrderNotOpenScreen */
  'You are currently not able to sign any documents. Please contact the person or organization that invited you to sign.':
    'Du kan för närvarande inte underteckna några dokument. Vänligen kontakta den person eller organisation som bjöd in dig att skriva under',
  'Signature order has been cancelled':
    'Signaturordern har avbrutits',
  'Signature order has expired':
    'Signaturordern har löpt ut',
  'Signature order is unavailable':
    'Signaturordern är inte tillgänglig',
  /* DownloadScreen */
  'Download unavailable': 'Nedlasting utilgjengelig',
  'Documents are not available yet, or you are not allowed to download.': 'Dokument är inte tillgängliga ännu, eller så får du inte ladda ner.',
  'Verify your identity to download documents': 'Verifiera din identitet för att ladda ner dokument',
  'Download documents': 'Ladda ner dokument',
  'Download expired': 'Nedlasting utgånget',
  'Documents are no longer available.': 'Dokumenten är inte längre tillgängliga.',
  /* UnvalidatedSignatoryViewerScreen */
  'You must identify yourself before you can view and sign the documents.': 
    'Du måste legitimera dig innan du kan se och underteckna dokumenten.'
};

const NB_NO_TRANSLATIONS : Translation = {
  /* ConfirmModal */
  'Cancel': 'Avbryt',
  'Confirm & continue': 'Bekreft & fortsett',
  'Continue': 'Fortsett',
  /* AnonymousViewerScreen */
  'An error occurred':
    'En feil oppstod',
  'You have ended up here by mistake, please go back to the person that invited you to sign and get a proper signature link.':
  'Du har havnet her ved en feiltakelse, vennligst gå tilbake til personen som inviterte deg til å signere og få en skikkelig signaturlenke.',
  /* EvidenceValidationErrorScreen */
  'The used e-ID does not match the person expected to sign these documents.':
    'Den brukte e-ID samsvarer ikke med personen som forventes å signere disse dokumentene.',
  'The used e-ID does not match any signed documents.': 
    'Den brukte e-ID samsvarer ikke med noen signerte dokumenter',
  'If you are signing on behalf or in company of another person, like your spouse, please ensure you are using the intended e-ID.':
    'Hvis du signerer på vegne av eller i selskap med en annen person, som din ektefelle, må du sørge for, at du bruker den tiltenkte e-ID.',
  'Sign with new e-ID':
    'Signer med ny e-ID',
  'Retry':
    'Prøv på nytt',
  /* DuplicateEvidenceErrorSCreen */
  'The e-ID you used has already previously been used to sign these documents.':
    'Den E-ID du brukte har allerede tidligere blitt brukt til å signere disse dokumentene.',
  'Please make sure you are using your personal e-ID when signing.':
    'Sørg for at du bruker din personlige e-ID, når du signerer.',
  /* ErrorScreen */
  'An error occurred. Please try again in 5 minutes':
    'En feil oppstod. Prøv igjen om 5 minutter.',
  'Our system is temporarily down or your network connection may be unstable.':
    'Systemet vårt er midlertidig nede eller nettverkstilkoblingen din kan være ustabil.',
  /* SignatoryViewerScreen */
  'Approve and sign':
    'Godkjenne og signere',
  'Approve & sign':
    'Godkjenne & signere',
  'Open and approve the document below':
    'Du har ett dokument til godkjenning og signering',
  'Open and approve the {documents} documents below':
    'Du har {documents} dokumenter til godkjenning og signering',
  'You must approve all documents before you can sign':
    'Du må godkjenne alle dokumenter før du kan signere.',
  'An error has occurred with the signature process.':
    'Det har oppstått en feil med signaturprosessen.',
  'Sign':
    'Signer',
  'I do not want to sign':
    'Jeg vil ikke signere',
  'documents':
    'dokumenter',
  'document':
    'dokument',
  'Signed':
    'Signert',
  'You have not signed':
    'Du har ikke signert',
  'You will be redirected...':
    'Du vil bli omdirigert...',
  'Reject':
    'Avvis',
  'Approve':
    'Godkjenne',
  'We are processing your data...':
    'Vi behandler dine data...',
  'Hold on a second...':
    'Vent to sekunder...',
  'Let us know why you do not want to sign.':
    'Fortell oss hvorfor du ikke vil signere.',
  'Don\'t see the popup?': 
    'Ser du ikke popup-vinduet?',
  'Try again':
    'Prøv igjen',
  'An error occurred while authenticating your e-ID to sign these documents.':
    'Det oppstod en feil under autentisering av din e-ID til å signere disse dokumentene.', 
  'An error occurred while authenticating your e-ID.':
    'Det oppstod en feil under autentisering av din e-ID.',
  'Loading PDF':
    'Laster PDF',
  'Open':
    'Åpne',
  /* DrawableEvidenceProviderScreen */
  'Back':
    'Tilbake',
  'Next':
    'Neste',
  'Erase':
    'Slette',
  'Draw your signature here':
    'Tegn signaturen din her',
  'Date':
    'Dato',
  'Full name':
    'Fullt navn',
  'Type in your full name':
    'Skriv inn ditt fulle navn',
  'Type in your full name in the field below':
    'Skriv inn ditt fulle navn i feltet nedenfor',
  'You must enter your full name before submitting':
    'Du må skrive inn fullt navn før du sender inn',
  'You must draw your signature before submitting':
    'Du må tegne signaturen din før du sender inn',
  'You must draw your signature and enter your full name before submitting':
    'Du må tegne signaturen din og skrive inn fullt navn før du sender inn',
  'Turn your phone horizontal':
    'Vend telefonen horisontalt',
  '(Having issues? Check if your phone has auto-rotation enabled...)':
    '(Har du problemer? Sjekk om telefonen din har automatisk rotasjon aktivert...)',
  'Approve your signature':
    'Godkjenne signaturen din',
  'You must draw a larger signature before submitting':
    'Du må tegne en større signatur før du sender inn',
  /* SignScreen */
  'Choose how you want to sign':
    'Velg hvordan du vil signere',
  'With your e-ID':
    'Med din e-ID',
  'Draw your signature':
    'Tegn signaturen din',
  'Sign flow on mobile device cancelled':
    'Signaturflow på mobilenheten er avbrutt',
  'Complete the signing flow on your mobile device':
    'Fullfør signaturflow på mobilenheten din',
  'Or sign with your mobile device by scanning the QR code':
    'Eller signer med mobilenheten din ved å skanne QR-koden',
  /* SignatoryDeletedScreen */
  'Invalid signature link':
    'Ugyldig signaturlenke',
  'The signature link you followed is no longer valid, please request a new one from the person or organization that invited you to sign':
    'Signaturlenken du fulgte er ikke lenger gyldig, vennligst be om en ny fra personen eller organisasjonen, som inviterte deg til å signere.',
  /* SignatureOrderNotOpenScreen */
  'You are currently not able to sign any documents. Please contact the person or organization that invited you to sign.':
    'Du kan for øyeblikket ikke signere noen dokumenter. Ta kontakt med personen eller organisasjonen, som inviterte deg til å signere.',
  'Signature order has been cancelled':
    'Signaturbestilling er avbrutt',
  'Signature order has expired':
    'Signaturbestilling er utløpt',
  'Signature order is unavailable':
    'Signaturbestilling er utilgjengelig',
  /* DownloadScreen */
  'Download unavailable': 'Nedlasting utilgjengelig',
  'Documents are not available yet, or you are not allowed to download.': 'Dokumenter er ikke tilgjengelige ennå, eller du har ikke lov til å laste ned.',
  'Verify your identity to download documents': 'Bekreft identiteten din for å laste ned dokumenter',
  'Download documents': 'Last ned dokumenter',
  'Download expired': 'Nedlastingen er utløpt',
  'Documents are no longer available.': 'Dokumenter er ikke lenger tilgjengelige.',
  /* UnvalidatedSignatoryViewerScreen */
  'You must identify yourself before you can view and sign the documents.':
    'Du må identifisere deg før du kan se og signere dokumentene.'
};
